import axios from "axios";

// const token = localStorage.access_token;
const headers = {"Content-Type": "application/json"};

const api = axios.create({
  baseURL: "https://dev.api.mavaerosafety.com/",
  headers: headers,
});

api.interceptors.request.use(
  (response) => {
    const token = localStorage.access_token;
    response.headers.Authorization =  token ? `Bearer ${token}` : '';
    return response;
  },
  (error) => {
    if (error.response.data.message) {
      error.message = error.response.data.message;
    }

    return Promise.reject(error);
  }
);

export default api;
