import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const AuthContext = React.createContext(null);

const initialState = {
  isLoggedIn: false,
  isLoginPending: false,
  loginError: null,
  userData: [],
};

export const AuthProvider = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState(initialState);

  const setLoginPending = (isLoginPending) =>
    setState({ ...state, isLoginPending });
  const setLoginSuccess = (isLoggedIn) => setState({ ...state, isLoggedIn });
  const setLoginError = (loginError) => setState({ ...state, loginError });
  const setUserData = (userData) => {
    setState({ ...state, isLoggedIn: true, userData });
  };
  /** Login API call */
  const fetchLogin = (email, password, callback) =>
    axios
      .post("https://dev.api.mavaerosafety.com/accounts/login/", {
        email: email,
        password: password,
      })
      .then(function (response) {
        return callback(response);
      })
      .catch(function (error) {
        return callback(error);
      });

  /**
   * Login method
   * Redirect to /dashboard if login is successfull
   */
  const login = (email, password) => {
    setLoginPending(true);
    setLoginSuccess(false);

    fetchLogin(email, password, (response) => {
      setLoginPending(false);

      if (response?.status === 200) {
        setUserData(response?.data[0]?.data?.user);
        localStorage.setItem(
          "access_token",
          response?.data[0]?.data?.token?.access
        );
        // added for now till we update the API to get user data
        const userData = JSON.stringify(response?.data[0]?.data?.user);
        localStorage.setItem("user", userData);
        navigate("/dashboard");
      } else {
        setLoginError(response.response?.data[0] || "Something went wrong!");
      }
    });
  };

  /**
   * Logout method
   * Redirect to /login page once logged-out
   */
  const logout = () => {
    setLoginPending(false);
    setLoginSuccess(false);
    setLoginError(null);
    localStorage.removeItem("access_token");
    navigate("/login");
  };

  return (
    <AuthContext.Provider
      value={{
        state,
        login,
        setUserData,
        setLoginPending,
        setLoginSuccess,
        setLoginError,
        logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
