/* eslint-disable */
import React from "react";
import { useNavigate } from "react-router";

import * as yup from "yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormLabel } from "@mui/material";

import { Button } from "../Button";

import "./style.css";
import { Add, RemoveCircle } from "@mui/icons-material";

export const Form = (props) => {
  const navigate = useNavigate();
  const {
    defaultValues,
    children,
    onSubmit,
    redirectUrl = "#",
    validationSchema,
    validationSchemaDependencies = [],
    submitButtonText = "Submit",
    apiError,
    mode = "onSubmit",
    submitButtonStyle = "primary",
    cancelButtonText = "Cancel",
    cancelButtonStyle = "secondary",
  } = props;
  const schema = yup
    .object()
    .shape(validationSchema, validationSchemaDependencies);
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(schema), mode: mode });

  const returnFormControl = (child) => {
    const { name } = child.props;

    return name ? (
      <Controller
        render={({ field }) => {
          return React.createElement(child.type, {
            ...{
              ...field,
              ...child.props,
              setValue,
              errors,
            },
          });
        }}
        control={control}
        name={name}
        defaultValue={defaultValues ? defaultValues[name] : ""}
        key={name}
      />
    ) : (
      child
    );
  };

  const handleCancel = async () => {
    redirecTo();
  };

  const redirecTo = () => {
    navigate(redirectUrl);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={`form__wrapper ${props.className || ``}`}>
        {apiError && <FormLabel error>{apiError}</FormLabel>}
        {/* {Array.isArray(children)
          ? children.map((child) => {
              return returnFormControl(child);
            })
          : returnFormControl(children)} */}

        {Array.isArray(children)
          ? children.map((child) => {
              const { isDynamicForm, getDynamicFormSize, isDisableButton } =
                child.props;
              if (isDynamicForm) {
                const { name, addLabel } = child.props;
                let { children, className } = child.props;
                children = Array.isArray(children) ? children : [children];
                const { fields, append, remove } = useFieldArray({
                  control,
                  name: name,
                });
                const watchFieldArray = watch(name);
                const controlledFields = fields.map((field, index) => {
                  return {
                    ...field,
                    ...watchFieldArray[index],
                  };
                });
                if (getDynamicFormSize) {
                  getDynamicFormSize(controlledFields?.length);
                }
                return (
                  <>
                    <div class="dynamic_forms">
                    {controlledFields.map((field, index) => {
                      return (
                        <div
                          className={
                            className
                              ? `dynamic__form--wrapper ${className}`
                              : "dynamic__form--wrapper"
                          }
                        >
                          <React.Fragment key={field.id}>
                            {children.map((childItem) =>
                              returnFormControl(
                                React.cloneElement(childItem, {
                                  name: `${name}.${index}.${childItem.props.name}`,
                                  fieldlabel: `${childItem.props.name}`
                                })
                              )
                            )}
                            {index !== 0 &&
                            <RemoveCircle
                              color="primary"
                              className="remove-button-wrap"
                              onClick={() => remove(index)}
                            />}
                          </React.Fragment>
                        </div>
                      );
                    })}
                    <Button
                      variant="contained"
                      disabled={isDisableButton}
                      type="button"
                      outline
                      className={`add-button-wrap add-button-wrap-${controlledFields?.length}`}
                      buttontext={addLabel}
                      onClick={(e) => {
                        e.preventDefault();
                        let appendList = {};
                        children.map((childItem) => {
                          const { defaultValue } = childItem.props;
                          appendList[childItem.props.name] = defaultValue || "";
                        });
                        append(appendList);
                      }}
                    >
                      {addLabel}
                    </Button>
                    </div>
                  </>
                );
              }

              return returnFormControl(child);
            })
          : returnFormControl(children)}
        <div className="form--button__wrapper">
          <Button
            type="submit"
            color={submitButtonStyle}
            className="btn__submit--form"
            variant="contained"
          >
            {submitButtonText}
          </Button>
          <Button
            color={cancelButtonStyle}
            onClick={handleCancel}
            className="btn__cancel--form"
            variant="contained"
          >
            {cancelButtonText}
          </Button>
        </div>
      </form>
    </>
  );
};
/* eslint-enable */