import { React } from "react";

import { AvatarMenu } from "../AvatarMenu";

import logo_white from "../../../assets/trainerX_logo_Blue.png";

export const Header = () => {
  return (
    <header>
      <nav>
        <div className="left-section">
          <img src={logo_white} className="logo_trainerx" alt="logo_trainerx" />
        </div>
        <AvatarMenu />
      </nav>
    </header>
  );
};
