import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Header } from "../../components/features/Header";
import { Footer } from "../../components/features/Footer";
import { Sidenav } from "../../components/features/Sidenav";
import { AuthContext } from "../../context/Auth.context.js";
import { StudentProvider } from "../../context/Student.context";
import { CommonProvider } from "../../context/Common.context";

const withLayout = (Component) => {
  return (props) => {
    const navigate = useNavigate();
    const contextData = useContext(AuthContext);
    const { userData } = contextData.state

    /**
     * Render component if logged-in;
     * else redirect to "/login" page
     */
    if (contextData.state.isLoggedIn) {
      return (
        <div className="wrapper">
          <Sidenav />
          <section className="main-body">
            <Header />
            <div className="container">
              <CommonProvider>
                <StudentProvider>
                  <Component {...props} />
                </StudentProvider>
              </CommonProvider>
            </div>
            <Footer
              instituteName={userData.institute_name}
            />
          </section>
        </div>
      );
    } else {
      useEffect(() => {
        navigate("/login");
      });
    }
  };
};
export default withLayout;
