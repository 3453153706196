import { React, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import { Box, Grid, Paper, Button, IconButton } from '@mui/material';
import { Edit, ArrowForwardIos, ArrowBackIosNew } from '@mui/icons-material';
import axios from 'axios';

import '../../styles/partials/_institueList.scss'

const baseURL = process.env.REACT_APP_API_ENDPOINT;

const InstituteList = () => {
  const navigate = useNavigate();
  const [instituteListDetails, setinstituteListDetails] = useState({});
  const [instituteList, setInstituteList] = useState([]);

  const token = localStorage.access_token;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }

  useEffect(()=>{
    axios.get(`${baseURL}accounts/institute/institute-list`, { headers }).then(response => {
        const { data } = response.data[0];
        setinstituteListDetails({...data});
        setInstituteList(data);
        console.log(response.data[0].data)
    })
    .catch(function (error) {
      console.log(error);
    });    
},[]);

const pagination = (dir) => {
  const { next, previous } = instituteListDetails; 
  const url = dir === 'next' ? next : previous;
  
  axios.get(url, { headers }).then(response => {
    const { data } = response;
    setinstituteListDetails(data);
    setInstituteList(data.results);
    })
    .catch(function (error) {
    console.log(error);
    });
}

const addUser = (userId) => {
  navigate('/add-institute-user', { state : {userId} }) 
 }

  return (
      <Box sx={{ flexGrow: 1 }} >
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>          
          <Grid item xs={2} sm={4} md={8}>
            <h2>Institute List:</h2>
          </Grid>
        </Grid>
        <Grid container className="grid-container" spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {instituteList.map((institute, index) => (
            <Grid item xs={2} sm={4} md={3} key={index}>
                <Paper className="paper">
                  <div className="institue-name">
                    <span>Institue Name:</span><br/>
                    { institute.name }
                  </div>
                </Paper>
                <div className="action-wrapper">
                  <Button variant="text" onClick={()=> addUser(institute.id)}><Edit className="icon"/> Add</Button>
                </div>
            </Grid>
          ))}
        </Grid>
        <div className="pagination rounded-icon-btn">                   
                <IconButton onClick={() => pagination('prev')} disabled={!instituteListDetails.previous} variant="outline"><ArrowBackIosNew size="small" /></IconButton>
                <IconButton onClick={() => pagination('next')} disabled={!instituteListDetails.next} variant="outline"><ArrowForwardIos /></IconButton>                
        </div>
      </Box>
  )
}

export default InstituteList;