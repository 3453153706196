import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";


import { Button } from '@mui/material';
import { AuthContext } from "../../context/Auth.context";
import { CommonContext } from "../../context/Common.context";

import withLayout from "../../hoc/Layout";
import InstituteList from "../Institute";

const Dashboard = () => {
  const navigate = useNavigate();
  const contextData = useContext(AuthContext);
  const commonContextData = useContext(CommonContext);
  const { role } = contextData.state?.userData;

  useEffect(() => {
    (async () => {
      await commonContextData.getDesignations();
    })();
  }, []);

  return (
    <div className="container-fluid">
      <div className="page-header">
        <h2>Dashboard</h2>
        { role === 4 && <Button variant="outlined" onClick={() => navigate('/add-institute')}>Add Institute</Button>}
      </div>
      <div>       
        {
          role === 2 ?
            <h2>Other Dashboard Data</h2>
            : role === 4 ?
              <InstituteList />
              : <h2>Other Dashboard Data</h2>
        }
        </div>
    </div>
  );
};

export default withLayout(Dashboard);
