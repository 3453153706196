import React from "react";
import PropTypes from "prop-types";
import { TextField as MInput } from "@mui/material";

var flatten = require("flat");

export const Input = (props) => {
  const { errors, ...others } = props;
  const flatError = flatten(errors);

  return (
    <MInput
      {...others}
      error={flatError[`${props.name}.message`]}
      helperText={flatError[`${props.name}.message`]}
      className="form--control"
    />
  );
};

Input.propTypes = {
  /**
   * Callback fired when the value is changed.
   *
   * @param {object} event The event source of the callback.
   * You can pull out the new value by accessing `event.target.value` (string).
   */
  onChange: PropTypes.func,
  /**
   * The variant to use.
   * @default 'outlined'
   */
  variant: PropTypes.oneOf(["standard", "filled", "outlined"]),
  /**
   * Props applied to the Input element.
   * It will be a [`FilledInput`](/material-ui/api/filled-input/),
   * [`OutlinedInput`](/material-ui/api/outlined-input/) or [`Input`](/material-ui/api/input/)
   * component depending on the `variant` prop value.
   */
};
