import React, { useContext, useState } from "react";

import swal from "sweetalert";

import api from "../utils/axios";
import EventDispatcher from "../utils/EventDispatcher";
import { AuthContext } from "./Auth.context";

export const CommonContext = React.createContext(null);

const initialState = {
  choiceFields: [],
  designations: [],
  userDetail: {},
  userQualification: {},
  userEmergency: {},
  userLicense: {},
  userExperience: {},
  userELPDetails: {},
  userAircraftEndorsementDetails: {},
  userInstrumentRatingDetails: {},
  userMedicalDetails: {},
  userOtherRatingDetails: {},
};

export const CommonProvider = (props) => {
  const authcontext = useContext(AuthContext)
  const [state, setState] = useState(initialState);

  const setChoiceFields = (choiceFields) => {
    setState((prevState) => ({ ...prevState, choiceFields }));
  };

  const setdesignation = (designations) => {
    setState((prevState) => ({ ...prevState, designations }));
  };

  const setUserDetail = (userDetail) => {
    setState((prevState) => ({ ...prevState, userDetail }));
  };

  const setUserEmergency = (userEmergency) => {
    setState((prevState) => ({ ...prevState, userEmergency }));
  };

  const setUserQualification = (userQualification) => {
    setState((prevState) => ({ ...prevState, userQualification }));
  };

  const setUserLicense = (userLicense) => {
    setState((prevState) => ({ ...prevState, userLicense }));
  };

  const setUserExperience = (userExperience) => {
    setState((prevState) => ({ ...prevState, userExperience }));
  };
  
  const setUserELPDetails = (userELPDetails) => {
    setState((prevState) => ({ ...prevState, userELPDetails }));
  };

  const setuserAircraftEndorsementDetails = (userAircraftEndorsementDetails) => {
    setState((prevState) => ({ ...prevState, userAircraftEndorsementDetails }));
  };

  const setuserInstrumentRatingDetails = (userInstrumentRatingDetails) => {
    setState((prevState) => ({ ...prevState, userInstrumentRatingDetails }));
  };

  const setuserMedicalDetails = (userMedicalDetails) => {
    setState((prevState) => ({ ...prevState, userMedicalDetails }));
  };

  const setuserOtherRatingDetails = (userOtherRatingDetails) => {
    setState((prevState) => ({ ...prevState, userOtherRatingDetails }));
  };

  
  /** Get designations */
  const getChoiceFields = async () => {
    try {
      const choices = await api.get(`accounts/license/get_choice_fields/`);
      setChoiceFields(choices?.data[0].data || []);
    } catch (error) {
      console.log("Error", error);
      authcontext.logout();
    }
  };

  /** Get designations */
  const getDesignations = async () => {
    try {
      const getDesignationData = await api.get(
        `accounts/account-user-invite/designation-list/`
      );
      setdesignation(getDesignationData?.data[0]?.data || []);
    } catch (error) {
      console.log("Error", error);
      authcontext.logout();
    }
  };

  /** Get single user (student/instructor) detail */
  const getUserDetails = async (id) => {
    try {
      const getUserData = await api.get(
        `accounts/user/view-user-profile?id=${id}`
      );

      setUserDetail(getUserData?.data[0]?.data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  /** Update personal profile (student/instructor) */
  const updateUserProfile = async (id, data) => {
    try {
      await api.put(`accounts/user/update_profile/?id=${id}`, data);
      swal("Profile updated Successfully", "", "success", {
        button: "Ok",
      }).then(() => {
        // callback();
      });
    } catch (error) {
      swal("Error in updating profile", "", "danger", {
        button: "Ok",
      });
    }
  };

  /** Activate / Deactivate user (student/instructor) */
  const deactivateUser = async (id, status, type, callback) => {
    const statusObj = { deactivate: status };
    try {
      await api.put(
        `accounts/account-user-invite/${id}/user-status-change/`,
        statusObj
      );
    } catch (error) {
      console.log("Error", error);
    }
  };

  /** Delete user (student/instructor) */
  const deleteUser = async (id, type, callback) => {
    try {
      await api.delete(`accounts/account-user-invite/${id}/delete-user/`);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const statusUpdate = async (id, status, callback) => {
    try {
      await api.put(`accounts/account-user-invite/${id}/user-status-change/`, {deactivate: status});
      callback();
    } catch (error) {
      console.log("Error", error);
    }
  }

  /** Get emergency profile (student/instructor) detail */
  const getUserEmergencyProfile = async (id) => {
    try {
      const getUserData = await api.get(
        `accounts/user_emergency/view-user-emergency/?user_id=${id}`
      );

      setUserEmergency(getUserData?.data[0]?.data);
    } catch (error) {
      console.log("Error", error);
    }
  };

  /** Update emergency profile (student/instructor) */
  const updateUserEmergencyProfile = async (data) => {
    try {
      await api.post(`accounts/user_emergency/add-user-emergency/`, data);

      swal("Emergency profile updated Successfully", "", "success", {
        button: "Ok",
      }).then(() => {
        // callback();
      });
    } catch (error) {
      swal("Error in updating emergency profile", "", "danger", {
        button: "Ok",
      });
    }
  };

  /** get qualification profile (student/instructor) */
  const getUserQualificationProfile = async (id) => {
    try {
      const result = await api.get(
        `accounts/user-qualification/view-user-qualifications/?user_id=${id}`
      );

      setUserQualification(result?.data[0]?.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  /** update qualification profile (student/instructor) */
  const updateUserQualificationProfile = async (data) => {
    try {
      await api.post(
        `accounts/user-qualification/add-user-qualification/`,
        data
      );

      swal("Qualification profile updated Successfully", "", "success", {
        button: "Ok",
      }).then(() => {
        // callback();
      });
    } catch (error) {
      swal("Error in updating qualification profile", "", "danger", {
        button: "Ok",
      });
    }
  };

  /** get experience profile (student/instructor) */
  const getUserExperienceProfile = async (id) => {
    try {
      const result = await api.get(
        `accounts/experience/view-user-experience/?user_id=${id}`
      );

      setUserExperience(result?.data[0]?.data);
    } catch (error) {
      console.log(error);
    }
  };

  /** update experience profile (student/instructor) */
  const updateUserExperienceProfile = async (data, callBack) => {
    try {
      await api.post(`accounts/experience/add-user-experience/`, data);
      EventDispatcher.dispatch("closeModal");

      swal("Experience profile updated Successfully", "", "success", {
        button: "Ok",
      });

      await getUserExperienceProfile(data.user_id);
      callBack();
    } catch (error) {
      swal("Error in updating experience profile", "", "danger", {
        button: "Ok",
      });
    }
  };

  /** get license profile (student/instructor) */
  const getUserLicenseProfile = async (id) => {
    try {
      const result = await api.get(
        `accounts/license/view-user-license/?user_id=${id}`
      );

      setUserLicense(result?.data[0]?.data);
    } catch (error) {
      console.log(error);
    }
  };

  /** update license profile (student/instructor) */
  const updateUserLicenseProfile = async (data, callback) => {
    try {
      await api.post(
        `accounts/license/add-user-license/`,
        data
      );
      swal("License profile updated Successfully", "", "success", {
        button: "Ok",
      }).then(() => {
        // callback();
      });
      
      await getUserLicenseProfile (data.user_id);
      callback();
    } catch (error) {
      swal("Error in updating license profile", "", "danger", {
        button: "Ok",
      });
    }
  };

  /** get ELP Details (student/instructor) */
  const getUserELPDetails = async (id) => {
    try {
      const result = await api.get(
        `accounts/elp/view-user-elp/?user_id=${id}`
      );
      console.log(result?.data[0]?.data);
      setUserELPDetails(result?.data[0]?.data);
    } catch (error) {
      console.log(error);
    }
  };

  /** update ELP Details (student/instructor) */
  const updateUserELPDetails = async (data, callBack) => {
    try {
      await api.post(`accounts/elp/add-user-elp/`, data);

      swal("ELP Details Added Successfully", "", "success", {
        button: "Ok",
      })
      
      await getUserELPDetails (data.user_id);
      callBack();

    } catch (error) {
      swal("Error in updating license profile", "", "danger", {
        button: "Ok",
      });
    }
  };

  
  /** get Aircraft Endorsement(student/instructor) */
  const getAircraftEndorsement = async (id) => {
    try {
      const result = await api.get(
        `accounts/endorsement/view-aircraft-endorsement/?user_id=${id}`
      );
      setuserAircraftEndorsementDetails(result?.data[0]?.data);
    } catch (error) {
      console.log(error);
    }
  };

  /** update Aircraft Endorsement (student/instructor) */
  const updateAircraftEndorsement = async (data, callBack) => {
    try {
      await api.post(`accounts/endorsement/add-aircraft-endorsement/`, data);

      swal("Aircraft Endorsement Details Added Successfully", "", "success", {
        button: "Ok",
      })
      
      await getAircraftEndorsement (data.user_id);
      callBack();

    } catch (error) {
      swal("Error in updating Aircraft Endorsement details", "", "danger", {
        button: "Ok",
      });
    }
  };


  /** get Instrument Rating (student/instructor) */
  const getInstrumentRating = async (id) => {
    try {
      const result = await api.get(
        `accounts/instrument/view-user-instrument-ratings/?user_id=${id}`
      );
      setuserInstrumentRatingDetails(result?.data[0]?.data);
    } catch (error) {
      console.log(error);
    }
  };

  /** update Instrument Rating (student/instructor) */
  const updateInstrumentRating = async (data, callBack) => {
    try {
      await api.post(`accounts/instrument/add-instrument-rating/`, data);

      swal("Instrument Rating Details Added Successfully", "", "success", {
        button: "Ok",
      })
      
      await getInstrumentRating(data.user_id);
      callBack();

    } catch (error) {
      swal("Error in updating Instrument Rating details", "", "danger", {
        button: "Ok",
      });
    }
  };

  
  /** get Medical Details (student/instructor) */
  const getuserMedicalDetails = async (id) => {
    try {
      const result = await api.get(
        `accounts/medical/view-user-medical/?user_id=${id}`
      );
      setuserMedicalDetails(result?.data[0]?.data);
    } catch (error) {
      console.log(error);
    }
  };

  /** update Medical Details (student/instructor) */
  const updateuserMedicalDetails = async (data, callBack) => {
    try {
      await api.post(`accounts/medical/add-user-medical/`, data);

      swal("Medical Details Added Successfully", "", "success", {
        button: "Ok",
      })
      
      await getuserMedicalDetails(data.user_id);
      callBack();

    } catch (error) {
      swal("Error in updating Medical Details", "", "danger", {
        button: "Ok",
      });
    }
  };

  /** get Other Rating Details (student/instructor) */
  const getuserOtherRatingDetails = async (id) => {
    try {
      const result = await api.get(
        `accounts/ratings/view-user-rating/?user_id=${id}`
      );
      setuserOtherRatingDetails(result?.data[0]?.data);
    } catch (error) {
      console.log(error);
    }
  };

  /** update Other Rating Details (student/instructor) */
  const updateuserOtherRatingDetails = async (data, callBack) => {
    try {
      await api.post(`accounts/ratings/add-user-ratings/`, data);

      swal("Rating Details Added Successfully", "", "success", {
        button: "Ok",
      })
      
      await getuserOtherRatingDetails(data.user_id);
      callBack();

    } catch (error) {
      swal("Error in updating Rating Details", "", "danger", {
        button: "Ok",
      });
    }
  };
  
  /** Delete user (student/instructor) */
  const deleteAction = async (id, type, endpoint, callback) => {
    try {
      await api.delete(`accounts/${type}/${id}/delete-${endpoint}/`);
      callback();
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <CommonContext.Provider
      value={{
        state,
        getChoiceFields,
        getDesignations,

        getUserDetails,
        updateUserProfile,
        deactivateUser,
        deleteUser,
        statusUpdate,

        getUserEmergencyProfile,
        updateUserEmergencyProfile,

        getUserQualificationProfile,
        updateUserQualificationProfile,

        getUserLicenseProfile,
        updateUserLicenseProfile,

        getUserExperienceProfile,
        updateUserExperienceProfile,
        
        getUserELPDetails,
        updateUserELPDetails,

        getAircraftEndorsement,
        updateAircraftEndorsement,
        
        getInstrumentRating,
        updateInstrumentRating,

        getuserMedicalDetails,
        updateuserMedicalDetails,

        getuserOtherRatingDetails,
        updateuserOtherRatingDetails,

        deleteAction,
      }}
    >
      {props.children}
    </CommonContext.Provider>
  );
};
